<template>
  <website>
    <template slot="toolbar">
      <search-toolbar v-model="searchTerm" :loading="loading" @input="onSearchTerm" />
    </template>
    <products-masonry :products="products" :loading="loading" />
    <v-pagination
      v-if="total > 0"
      v-model="page"
      dark
      class="mt-2"
      color="blue-grey darken-3"
      :loading="loading"
      :disabled="loading"
      :length="totalPages"
      @input="onChangePage"
    />
  </website>
</template>

<script>
  import Website from '../templates/Website/Website.vue'
  import Search from '@peynman/press-vue-core/mixins/Search'
  import SearchToolbar from '../components/Search/SearchToolbar.vue'
  import ProductsMasonry from '../components/Product/ProductsMasonry.vue'

  export default {
    name: 'ModenaSearch',
    components: {
      Website,
      SearchToolbar,
      ProductsMasonry,
    },
    mixins: [
      Search,
    ],
  }
</script>
