<template>
  <v-toolbar
    :color="theme.website.cards.color"
    :dark="theme.website.cards.dark"
    :light="theme.website.cards.light"
    flat
    tile
  >
    <v-text-field
      v-model="internalValue"
      dense
      hide-details
      full-width
      :label="$t('components.website.search.label')"
      :loading="loading"
      clearable
      @keyup="onKeyUp"
    >
      <template v-slot:append>
        <v-btn text dense :loading="loading" @click="onSearch">{{ $t('components.website.search.search') }}</v-btn>
      </template>
    </v-text-field>
  </v-toolbar>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'

  export default {
    name: 'SearchToolbar',
    mixins: [
      Themeable,
    ],
    props: {
      value: String,
      loading: Boolean,
    },
    data: vm => ({
      internalValue: vm.value,
    }),
    watch: {
      value () {
        this.internalValue = this.value
      },
    },
    methods: {
      onKeyUp (e) {
        if (e.keyCode === 13) {
          this.onSearch()
        }
      },
      onSearch () {
        this.$emit('input', this.internalValue)
      },
    },
  }
</script>
